import React from "react";
import HeroContainer from "../Layouts/Home/HeroContainer";
import AboutUsSection from "../Layouts/Home/AboutUsSection";
import IndustriesWeWorkSection from "../Layouts/Home/IndustriesWeWorkSection";
import ServicesSection from "../Layouts/Home/ServicesSection";
import OurMissionSection from "../Layouts/Home/OurMissionSection";
// import BrandsSection from "../Layouts/Home/BrandsSection";
import TestimonialSection from "../Layouts/Home/TestimonialSection";
import InformationSection from "../Layouts/Home/InformationSection";
import ClientSliderContainer from "../Layouts/Services/SliderContainer/ClientSlider/ClientSliderContainer";
// import Test from "../Layouts/Services/AnimatedHeroSlider/Product1/Test";
// import HomeSecondSlide from "../Components/ui/HomeSecondSlide";
// import { OrbitingCirclesDemo } from "../Components/ui/HomeFirstSlide";
// import HomeThirdSlide from "../Components/ui/HomeThirdSlide";
import SliderSection from "../Layouts/Home/SliderSection";
// import MapSection from "../Layouts/Home/MapSection";

const Home = () => {
  return (
    <>
      {/* <OrbitingCirclesDemo /> */}
      {/* <HomeThirdSlide /> */}
      {/* <HomeSecondSlide /> */}
      {/* <Test /> */}
      <SliderSection />
      <HeroContainer />
      <AboutUsSection />
      <IndustriesWeWorkSection />
      <ServicesSection />
      <OurMissionSection />
      <ClientSliderContainer />
      {/* <BrandsSection /> */}
      <TestimonialSection />
      <InformationSection />
      {/* <MapSection /> */}
    </>
  );
};

export default Home;
