import React, { useEffect, useRef, useState } from "react";
import TextileImg from "../../Assets/Images/industryWeSurve/textile.png";
import ChemicalImg from "../../Assets/Images/industryWeSurve/chemistry.png";
import AutomationImg from "../../Assets/Images/industryWeSurve/engineering.png";
import HospitalImg from "../../Assets/Images/industryWeSurve/hospital.png";
import AgricultureImg from "../../Assets/Images/industryWeSurve/tractor.png";
import FactoriesImg from "../../Assets/Images/industryWeSurve/factory.png";
import CementsImg from "../../Assets/Images/industryWeSurve/cement.png";
import BusinessImg from "../../Assets/Images/industryWeSurve/business.png";
import HotelImg from "../../Assets/Images/industryWeSurve/hotel.png";
import StoneImg from "../../Assets/Images/industryWeSurve/stone.png";
import PowerPlantImg from "../../Assets/Images/industryWeSurve/powerPlants.png";
import AlmuniumImg from "../../Assets/Images/industryWeSurve/aluminum.png";
import CoalImg from "../../Assets/Images/industryWeSurve/coal.png";
import PlasticImg from "../../Assets/Images/industryWeSurve/plastic.png";
import ConstructionImg from "../../Assets/Images/industryWeSurve/construction.png";
import RealStateImg from "../../Assets/Images/industryWeSurve/realState.png";
import SugarImg from "../../Assets/Images/industryWeSurve/sugar.png";
import TobaccoImg from "../../Assets/Images/industryWeSurve/tobacco.png";
import DistelleryImg from "../../Assets/Images/industryWeSurve/distillery.png";
import RiceImg from "../../Assets/Images/industryWeSurve/rice.png";
import BioImg from "../../Assets/Images/industryWeSurve/bio.png";
import EquipmentsImg from "../../Assets/Images/industryWeSurve/equipment.png";
import PetroliumImg from "../../Assets/Images/industryWeSurve/petrolium.png";
import MetalImg from "../../Assets/Images/industryWeSurve/metal.png";
import PaperImg from "../../Assets/Images/industryWeSurve/paper.png";
import DrugsImg from "../../Assets/Images/industryWeSurve/drugs.png";
import CommercialImg from "../../Assets/Images/industryWeSurve/commercial.png";
import DairyImg from "../../Assets/Images/industryWeSurve/dairy.png";
import FoodImg from "../../Assets/Images/industryWeSurve/food.png";
import IndustrySliderContainer from "../Services/SliderContainer/IndustrySlider/IndustrySliderContainer";

const IndustriesWeWorkSection = () => {
  // const containerRef = useRef(null);
  // const contentRef = useRef(null);
  // const scrollValueRef = useRef(0);
  // const scrollContainerLengthRef = useRef(0);
  // const scrollingRight = useRef(true);
  // const childCountRef = useRef(0);

  // useEffect(() => {
  //   scrollContainerLengthRef.current =
  //     containerRef.current.children.length * contentRef.current.offsetWidth -
  //     containerRef.current.offsetWidth;
  // }, []);

  // const scrollLeft = () => {
  //   const container = containerRef.current;
  //   const contentWidth = contentRef.current.offsetWidth;
  //   if (scrollValueRef.current > 0) {
  //     // console.log(scrollValueRef.current, scrollContainerLengthRef.current);
  //     scrollValueRef.current -= contentWidth;
  //     container.scrollBy({ top: 0, left: -contentWidth, behavior: "smooth" });
  //   } else {
  //     scrollingRight.current = true; // Switch to scrolling right
  //   }
  // };

  // const scrollRight = () => {
  //   const container = containerRef.current;
  //   const contentWidth = contentRef.current.offsetWidth;
  //   if (scrollValueRef.current < scrollContainerLengthRef.current + 10) {
  //     // console.log(scrollValueRef.current, scrollContainerLengthRef.current);
  //     scrollValueRef.current += contentWidth;
  //     container.scrollBy({ top: 0, left: contentWidth, behavior: "smooth" });
  //   } else {
  //     scrollingRight.current = false; // Switch to scrolling left
  //   }
  // };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (scrollingRight.current) {
  //       scrollRight();
  //     } else {
  //       scrollLeft();
  //     }
  //   }, 2000);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <div className="industries__container py-4 py-sm-5 skew__nagitive">
      <div className="px-3 px-sm-4 skew__positive py-4">
        <div className="row g-3 g-sm-4">
          <div className="col-12">
            <div className="d-flex align-itmes-center justify-content-center">
              <h1 className="bottom__heading">Industries We Serve</h1>
            </div>
            <div className="mt-3">
              <p className="text-center">
                {
                  "We are continuously serving to around 200 industries. E2I maintain a healthy and fruitful relation with our clients. With decades of experience in water and waste water treatment, Envirozone Equipments & Instruments personnel are dedicated to providing customized engineering solutions to clients while minimizing overall project cost and surpassing extreme quality expectations."
                }
              </p>
            </div>
          </div>
          {/* <div className="relative px-4">
            <div
              className="absolute backdrop-brightness-50 p-[11px] rounded-full left-0 top-[40%] flex justify-center items-center cursor-pointer"
              onClick={scrollLeft}
            >
              <span className="material-symbols-outlined text-white font-bold">
                arrow_back
              </span>
            </div>
            <div
              className="absolute backdrop-brightness-50 p-[11px] rounded-full right-0 top-[40%] flex justify-center items-center cursor-pointer"
              onClick={scrollRight}
            >
              <span className="material-symbols-outlined text-white font-bold">
                arrow_forward
              </span>
            </div>
            <div
              className="flex gap-4 items-center overflow-x-auto"
              style={{ scrollbarWidth: "none" }}
              ref={containerRef}
            >
              <div className="col-6 col-sm-4 col-lg-2" ref={contentRef}>
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={TextileImg} className="w-100" alt="" />
                  <h4 className="mt-3 ">Textile</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={ChemicalImg} className="w-100" alt="" />
                  <h4 className="mt-3">Chemical</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={AutomationImg} className="w-100" alt="" />
                  <h4 className="mt-3">Automation</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={HospitalImg} className="w-100" alt="" />
                  <h4 className="mt-3">Hospital</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={AgricultureImg} className="w-100" alt="" />
                  <h4 className="mt-3">Agriculture</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={FactoriesImg} className="w-100" alt="" />
                  <h4 className="mt-3">Factories</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={AgricultureImg} className="w-100" alt="" />
                  <h4 className="mt-3">Agriculture</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={CementsImg} className="w-100" alt="" />
                  <h4 className="mt-3">Cement</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={BusinessImg} className="w-100" alt="" />
                  <h4 className="mt-3">Businesses</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={HotelImg} className="w-100" alt="" />
                  <h4 className="mt-3">Hotels</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={StoneImg} className="w-100" alt="" />
                  <h4 className="mt-3">Stone Crusher</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={PowerPlantImg} className="w-100" alt="" />
                  <h4 className="mt-3">Power Plants</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={AlmuniumImg} className="w-100" alt="" />
                  <h4 className="mt-3">Almunium</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={CoalImg} className="w-100" alt="" />
                  <h4 className="mt-3">Coal</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={PlasticImg} className="w-100" alt="" />
                  <h4 className="mt-3">Plastic</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={ConstructionImg} className="w-100" alt="" />
                  <h4 className="mt-3">Constructions</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={RealStateImg} className="w-100" alt="" />
                  <h4 className="mt-3">Real States</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={SugarImg} className="w-100" alt="" />
                  <h4 className="mt-3">Sugar</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={TobaccoImg} className="w-100" alt="" />
                  <h4 className="mt-3">Tobacco</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={DistelleryImg} className="w-100" alt="" />
                  <h4 className="mt-3">Distillery</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={RiceImg} className="w-100" alt="" />
                  <h4 className="mt-3">Rice Mill</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={BioImg} className="w-100" alt="" />
                  <h4 className="mt-3">Biomedicales</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={EquipmentsImg} className="w-100" alt="" />
                  <h4 className="mt-3">Equipments</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={PetroliumImg} className="w-100" alt="" />
                  <h4 className="mt-3">Petroleum</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={MetalImg} className="w-100" alt="" />
                  <h4 className="mt-3">Metal</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={PaperImg} className="w-100" alt="" />
                  <h4 className="mt-3">Paper</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={DrugsImg} className="w-100" alt="" />
                  <h4 className="mt-3">Drugs & Pharma</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={CommercialImg} className="w-100" alt="" />
                  <h4 className="mt-3">Commercial</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={DairyImg} className="w-100" alt="" />
                  <h4 className="mt-3">Dairy</h4>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
                  <img src={FoodImg} className="w-100" alt="" />
                  <h4 className="mt-3">Food</h4>
                </div>
              </div>
            </div>
          </div> */}
          <IndustrySliderContainer />
        </div>
      </div>
    </div>
  );
};

export default IndustriesWeWorkSection;
