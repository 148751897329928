import { configureStore } from "@reduxjs/toolkit";
import openModelReducer from "./Slices/openModelSlice.js";
import userReducer from "./Slices/userSlice.js";

export const store = configureStore({
  reducer: {
    openModel: openModelReducer,
    user: userReducer,
  },
  devTools: true,
});
