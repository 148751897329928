import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

const initialState = {};

// Creating Method
export const getAllProducts = createAsyncThunk(
  "/get/all/products",
  async () => {
    try {
      const res = await axios.get(`${window.apiURL}/product/all`);
      const getAllProductsId = toast.loading("Wait! We Fetching All Products.");
      if (res.status === 200) {
        toast.dismiss(getAllProductsId);
        toast.success(res?.data?.message);
        return (await res).data;
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.message);
      }
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const {} = productSlice.actions;
export default productSlice.reducer;
