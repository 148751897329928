import React, { useEffect, useState } from "react";
import { useTheme } from "next-themes";

import Particles from "../magicui/particles";
import OrbitingCircles from "../magicui/orbiting-circles";
import logo1 from "../../Assets/Images/MagicUI/Orbit/logo1.png";
import logo2 from "../../Assets/Images/MagicUI/Orbit/logo2.png";
import air from "../../Assets/Images/MagicUI/Orbit/air.png";
import water from "../../Assets/Images/MagicUI/Orbit/water.png";
import sound from "../../Assets/Images/MagicUI/Orbit/sound.png";
import soil from "../../Assets/Images/MagicUI/Orbit/soil.png";
import profile from "../../Assets/Images/MagicUI/Orbit/profile.jpg";
import main from "../../Assets/Images/MagicUI/Product/main.jpg";

export function OrbitingCirclesDemo() {
  const { theme } = useTheme();
  const [color, setColor] = useState("#86ac6d");

  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, [theme]);
  return (
    // <div className="flex flex-col md:flex-row">
    <div>
      <div className="overflow-hidden rounded-lg border bg-green-100 md:shadow-xl">
        {/* <div className="relative md:flex h-[82vh] w-[50vw] hidden md:visible flex-col items-center justify-center overflow-hidden rounded-lg border bg-green-100 md:shadow-xl"> */}
        <img src={main} alt="profile" className="h-[86vh] w-[100vw]" />
      </div>
      {/* <div className="relative flex w-[100vw] h-[70vh] md:h-[82vh] md:w-[50vw] flex-col items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
        <Particles
          className="absolute inset-0"
          quantity={150}
          ease={80}
          color={color}
          refresh
        />
        <div className="relative flex w-[100vw] h-[70vh] md:h-[82vh] md:w-[50vw] flex-col items-center justify-center overflow-hidden rounded-lg border md:shadow-xl">
          <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-green-400 to-green-800 bg-clip-text text-center text-6xl font-semibold leading-none text-transparent">
            E2I
          </span>

          <OrbitingCircles
            className="size-[100px] border-none bg-transparent"
            duration={20}
            delay={20}
            radius={90}
          >
            <img src={logo1} alt="logo1" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[100px] border-none bg-transparent"
            duration={20}
            delay={10}
            radius={90}
          >
            <img src={logo2} alt="logo1" />
          </OrbitingCircles>

          <OrbitingCircles
            className="size-[100px] border-none bg-transparent"
            radius={200}
            duration={20}
            reverse
          >
            <img src={air} alt="air" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[100px] border-none bg-transparent"
            radius={200}
            duration={20}
            delay={5}
            reverse
          >
            <img src={water} alt="water" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[100px] border-none bg-transparent"
            radius={200}
            duration={20}
            delay={20}
            reverse
          >
            <img src={sound} alt="sound" />
          </OrbitingCircles>
          <OrbitingCircles
            className="size-[100px] border-none bg-transparent"
            radius={200}
            duration={20}
            delay={15}
            reverse
          >
            <img src={soil} alt="soil" />
          </OrbitingCircles>
        </div>
      </div> */}
    </div>
  );
}
