import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import cpcbImg from "../../../../Assets/Images/home/real-time-data1.jpg";

import "./ServiceSectionSliderContainer.css";

export default function ServiceSectionSliderContainer() {
  return (
    <div className="serviceSectionContainer">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 5,
          stretch: 0,
          depth: 25,
          modifier: 5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="serviceSection_swiper_container"
      >
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="serviceSection_swiper_slide">
          <div className="shadow">
            <img src={cpcbImg} className="rounded-3" alt="" />
          </div>
        </SwiperSlide>

        <div className="serviceSection-slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>

          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>

          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}
