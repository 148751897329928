import React, { useEffect, useRef } from "react";
import product1 from "../../Assets/Images/home/product-1.png";

import cpcbImg from "../../Assets/Images/home/real-time-data1.jpg";
import ServiceSectionSliderContainer from "../Services/SliderContainer/ServiceSectionSlider/ServiceSectionSliderContainer";
import ProductSliderContainer from "../Services/SliderContainer/ProductSlider/ProductSliderContainer";

const ServicesSection = () => {
  // Product Scroll
  // const productContainerRef = useRef(null);
  // const productContentRef = useRef(null);
  // const productScrollValueRef = useRef(0);
  // const productScrollContainerLengthRef = useRef(0);
  // const productScrollingRight = useRef(true);

  // useEffect(() => {
  //   productScrollContainerLengthRef.current =
  //     productContainerRef.current.children.length *
  //       productContentRef.current.offsetWidth -
  //     productContainerRef.current.offsetWidth;
  // });

  // const productScrollLeft = () => {
  //   const container = productContainerRef.current;
  //   const contentWidth = productContentRef.current.offsetWidth;
  //   if (productScrollValueRef.current > 0) {
  //     productScrollValueRef.current -= contentWidth;
  //     container.scrollBy({ top: 0, left: -contentWidth, behavior: "smooth" });
  //   } else {
  //     productScrollingRight.current = true;
  //   }
  // };

  // const productScrollRight = () => {
  //   const container = productContainerRef.current;
  //   const contentWidth = productContentRef.current.offsetWidth;
  //   if (
  //     productScrollValueRef.current <
  //     productScrollContainerLengthRef.current + 10
  //   ) {
  //     productScrollValueRef.current += contentWidth;
  //     container.scrollBy({ top: 0, left: contentWidth, behavior: "smooth" });
  //   } else {
  //     productScrollingRight.current = false;
  //   }
  // };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (productScrollingRight.current) {
  //       productScrollRight();
  //     } else {
  //       productScrollLeft();
  //     }
  //   }, 2000);

  //   return () => clearInterval(intervalId);
  // }, []);

  // PCB Scroll
  // const pcbContainerRef = useRef(null);
  // const pcbContentRef = useRef(null);
  // const pcbScrollValueRef = useRef(0);
  // const pcbScrollContainerLengthRef = useRef(0);
  // const pcbScrollingRight = useRef(true);

  // useEffect(() => {
  //   pcbScrollContainerLengthRef.current =
  //     pcbContainerRef.current.children.length *
  //       pcbContentRef.current.offsetWidth -
  //     pcbContainerRef.current.offsetWidth;
  // });

  // const pcbScrollLeft = () => {
  //   const container = pcbContainerRef.current;
  //   const contentWidth = pcbContentRef.current.offsetWidth;
  //   if (pcbScrollValueRef.current > 0) {
  //     pcbScrollValueRef.current -= contentWidth;
  //     container.scrollBy({ top: 0, left: -contentWidth, behavior: "smooth" });
  //   } else {
  //     pcbScrollingRight.current = true;
  //   }
  // };

  // const pcbScrollRight = () => {
  //   const container = pcbContainerRef.current;
  //   const contentWidth = pcbContentRef.current.offsetWidth;
  //   if (pcbScrollValueRef.current < pcbScrollContainerLengthRef.current + 10) {
  //     pcbScrollValueRef.current += contentWidth;
  //     container.scrollBy({ top: 0, left: contentWidth, behavior: "smooth" });
  //   } else {
  //     pcbScrollingRight.current = false;
  //   }
  // };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (pcbScrollingRight.current) {
  //       pcbScrollRight();
  //     } else {
  //       pcbScrollLeft();
  //     }
  //   }, 2000);

  //   return () => clearInterval(intervalId);
  // }, []);
  return (
    <div className="services__container pt-5 mt-md-5">
      <div className="px-3 px-sm-4">
        <div className="row g-3 g-sm-4">
          <div className="col-12">
            <div className="d-flex align-itmes-center justify-content-center">
              <h1 className="bottom__heading">{"Our Services & Products"}</h1>
            </div>
          </div>

          <div className="col-12">
            <h2 className="text-center ">
              We Are Providing Best Monitoring System
            </h2>
          </div>
          {/* <div className="relative">
            <div
              className="absolute backdrop-brightness-50 p-[11px] rounded-full left-0 top-[42%] flex justify-center items-center cursor-pointer"
              onClick={productScrollLeft}
            >
              <span className="material-symbols-outlined text-white font-bold">
                arrow_back
              </span>
            </div>
            <div
              className="absolute backdrop-brightness-50 p-[11px] rounded-full right-0 top-[42%] flex justify-center items-center cursor-pointer"
              onClick={productScrollRight}
            >
              <span className="material-symbols-outlined text-white font-bold">
                arrow_forward
              </span>
            </div>
            <div
              className="scrollable-container"
              style={{
                display: "flex",
                gap: "16px",
                overflowX: "scroll",
                scrollbarWidth: "none",
                padding: "8px",
                paddingBottom: "14px",
                // scrollbarColor: "#87b452 #f1f1f1",
              }}
              ref={productContainerRef}
            >
              <div className="col-12 col-sm-6 col-lg-3" ref={productContentRef}>
                <div className="bg-light shadow rounded-3 p-2">
                  <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
                    <img src={product1} className="w-75" alt="" />
                  </div>
                  <h3 className="text-center mt-3">
                    Environmental Monitoring System
                  </h3>
                  <p className="text-center">
                    It describes the processes and activities that need to take
                    place to characterize and monitor the quality of
                    environment.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="services__btn__read" href="#">
                      READ MORE
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      className="services__btn__view main__contact__btn my-3"
                      href="#"
                    >
                      VIEW PRODUCTS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-light shadow rounded-3 p-2">
                  <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
                    <img src={product1} className="w-75" alt="" />
                  </div>
                  <h3 className="text-center mt-3">
                    Environmental Monitoring System
                  </h3>
                  <p className="text-center">
                    It describes the processes and activities that need to take
                    place to characterize and monitor the quality of
                    environment.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="services__btn__read" href="#">
                      READ MORE
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      className="services__btn__view main__contact__btn my-3"
                      href="#"
                    >
                      VIEW PRODUCTS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-light shadow rounded-3 p-2">
                  <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
                    <img src={product1} className="w-75" alt="" />
                  </div>
                  <h3 className="text-center mt-3">
                    Environmental Monitoring System
                  </h3>
                  <p className="text-center">
                    It describes the processes and activities that need to take
                    place to characterize and monitor the quality of
                    environment.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="services__btn__read" href="#">
                      READ MORE
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      className="services__btn__view main__contact__btn my-3"
                      href="#"
                    >
                      VIEW PRODUCTS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-light shadow rounded-3 p-2">
                  <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
                    <img src={product1} className="w-75" alt="" />
                  </div>
                  <h3 className="text-center mt-3">
                    Environmental Monitoring System
                  </h3>
                  <p className="text-center">
                    It describes the processes and activities that need to take
                    place to characterize and monitor the quality of
                    environment.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="services__btn__read" href="#">
                      READ MORE
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      className="services__btn__view main__contact__btn my-3"
                      href="#"
                    >
                      VIEW PRODUCTS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-light shadow rounded-3 p-2">
                  <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
                    <img src={product1} className="w-75" alt="" />
                  </div>
                  <h3 className="text-center mt-3">
                    Environmental Monitoring System
                  </h3>
                  <p className="text-center">
                    It describes the processes and activities that need to take
                    place to characterize and monitor the quality of
                    environment.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="services__btn__read" href="#">
                      READ MORE
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      className="services__btn__view main__contact__btn my-3"
                      href="#"
                    >
                      VIEW PRODUCTS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-light shadow rounded-3 p-2">
                  <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
                    <img src={product1} className="w-75" alt="" />
                  </div>
                  <h3 className="text-center mt-3">
                    Environmental Monitoring System
                  </h3>
                  <p className="text-center">
                    It describes the processes and activities that need to take
                    place to characterize and monitor the quality of
                    environment.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="services__btn__read" href="#">
                      READ MORE
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      className="services__btn__view main__contact__btn my-3"
                      href="#"
                    >
                      VIEW PRODUCTS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-light shadow rounded-3 p-2">
                  <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
                    <img src={product1} className="w-75" alt="" />
                  </div>
                  <h3 className="text-center mt-3">
                    Environmental Monitoring System
                  </h3>
                  <p className="text-center">
                    It describes the processes and activities that need to take
                    place to characterize and monitor the quality of
                    environment.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="services__btn__read" href="#">
                      READ MORE
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      className="services__btn__view main__contact__btn my-3"
                      href="#"
                    >
                      VIEW PRODUCTS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-light shadow rounded-3 p-2">
                  <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
                    <img src={product1} className="w-75" alt="" />
                  </div>
                  <h3 className="text-center mt-3">
                    Environmental Monitoring System
                  </h3>
                  <p className="text-center">
                    It describes the processes and activities that need to take
                    place to characterize and monitor the quality of
                    environment.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="services__btn__read" href="#">
                      READ MORE
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      className="services__btn__view main__contact__btn my-3"
                      href="#"
                    >
                      VIEW PRODUCTS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <ProductSliderContainer />

          <div className="col-12 mt-5">
            <div className="d-flex align-itmes-center justify-content-center">
              <h1 className="bottom__heading">Real Time Data Monitoring</h1>
            </div>
          </div>
          {/* <div className="relative">
            <div
              className="absolute backdrop-brightness-50 p-[10px] rounded-full left-0 top-[40%] flex justify-center items-center cursor-pointer"
              onClick={pcbScrollLeft}
            >
              <span className="material-symbols-outlined text-white font-bold">
                arrow_back
              </span>
            </div>
            <div
              className="absolute backdrop-brightness-50 p-[10px] rounded-full right-0 top-[40%] flex justify-center items-center cursor-pointer"
              onClick={pcbScrollRight}
            >
              <span className="material-symbols-outlined text-white font-bold">
                arrow_forward
              </span>
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                overflowX: "scroll",
                scrollbarWidth: "none",
                padding: "8px",
                paddingBottom: "14px",
                scrollbarColor: "#87b452 #f1f1f1",
              }}
              ref={pcbContainerRef}
            >
              <div className="col-12 col-md-4" ref={pcbContentRef}>
                <div className="shadow">
                  <img src={cpcbImg} className="w-100 rounded-3" alt="" />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="shadow">
                  <img src={cpcbImg} className="w-100 rounded-3" alt="" />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="shadow">
                  <img src={cpcbImg} className="w-100 rounded-3" alt="" />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="shadow">
                  <img src={cpcbImg} className="w-100 rounded-3" alt="" />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="shadow">
                  <img src={cpcbImg} className="w-100 rounded-3" alt="" />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="shadow">
                  <img src={cpcbImg} className="w-100 rounded-3" alt="" />
                </div>
              </div>
            </div>
          </div> */}

          <ServiceSectionSliderContainer />
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
