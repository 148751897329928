import { useState } from "react";
import { Outlet, Link, NavLink, useNavigate } from "react-router-dom";
import logo from "./../Assets/Images/logo-trans-510x165-1.png";
import { useSelector, useDispatch } from "react-redux";
import { open, close } from "../Redux/Slices/openModelSlice.js";
import { signOut } from "../Redux/Slices/userSlice.js";

function Navbar() {
  const [onHover, setOnHover] = useState(false);
  const [humShowHide, setHumShowHide] = useState(false);
  const navigate = useNavigate();
  // Redux Import
  const signUpInBtnState = useSelector((state) => state.openModel.signUpInBtn);
  const isUserLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const dispatch = useDispatch();

  const navHideShow = function () {
    humShowHide ? setHumShowHide(false) : setHumShowHide(true);
  };

  // Handle Sign In & Sign Up Button
  const handleSignUpInBtn = () => {
    if (signUpInBtnState === true) {
      dispatch(close());
    } else {
      dispatch(open());
    }
  };

  return (
    <>
      <div className="nav__wrapper">
        <div className="container-xxl px-3 px-sm-4">
          <div className="topnav__container">
            <div>
              <i className="bi bi-telephone-fill"></i>
              {/* <a href="tel:+91 9871888359">+91 9871888359</a> */}
              <a href="tel:+91 8287411824">+91 8287411824</a>
            </div>
            <div className="ms-3">
              <i className="bi bi-envelope-fill"></i>
              {/* <a href="mailto:info@e2i.co.in"> info@e2i.co.in </a> */}
              <a href="mailto:mktg.eei@gmail.com"> mktg.eei@gmail.com </a>
            </div>
          </div>
          <div className="mainnav__container">
            <div className="logo__container ">
              <NavLink
                className={({ isActive }) => (isActive ? "" : "")}
                to="/"
              >
                <img src={logo} alt="" />
              </NavLink>
            </div>
            <div
              className={`links__container  ${
                humShowHide ? "" : "links__container__show"
              }`}
            >
              <ul className="gap-2 md:gap-3 lg:gap-3">
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active main__link" : "main__link"
                    }
                    onClick={navHideShow}
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active main__link" : "main__link"
                    }
                    onClick={navHideShow}
                    to="/aboutus"
                  >
                    About Us
                  </NavLink>
                </li>

                {onHover ? (
                  <div
                    className="relative z-10"
                    onMouseEnter={() => setOnHover(true)}
                    onMouseLeave={() => setOnHover(false)}
                  >
                    <div className="absolute top-[2.6rem] md:top-[2.8rem] lg:top-[4.4rem] left-[16%] md:left-[35%] lg:left-0 flex flex-col gap-2.5 justify-center items-center bg-white h-fit w-fit p-2 shadow-lg rounded-b-xl">
                      <li className="w-full">
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "active main__link full__length"
                              : "main__link full__length"
                          }
                          onClick={navHideShow}
                          to="/ems"
                        >
                          Effluent Monitoring Systems
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "active main__link full__length"
                              : "main__link full__length"
                          }
                          onClick={navHideShow}
                          to="/ems"
                        >
                          Emission Monitoring Systems
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "active main__link full__length"
                              : "main__link full__length"
                          }
                          onClick={navHideShow}
                          to="/ams"
                        >
                          Ambient Air Monitoring System
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "active main__link full__length"
                              : "main__link full__length"
                          }
                          onClick={navHideShow}
                          to="/opc"
                        >
                          Online PTZ Camera
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "active main__link full__length"
                              : "main__link full__length"
                          }
                          onClick={navHideShow}
                          to="/services"
                        >
                          All
                        </NavLink>
                      </li>
                    </div>
                  </div>
                ) : null}
                <li
                  onMouseEnter={() => setOnHover(!onHover)}
                  onMouseLeave={() => setOnHover(false)}
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active main__link" : "main__link"
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      // navHideShow();
                      setOnHover(!onHover);
                    }}
                    to="/services"
                  >
                    Services&nbsp;
                    <span className="md:hidden lg:hidden inline text-sm">
                      ▼
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active main__link" : "main__link"
                    }
                    onClick={navHideShow}
                    to="/products"
                  >
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active main__link" : "main__link"
                    }
                    onClick={navHideShow}
                    to="/industry"
                  >
                    Industry
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active main__link" : "main__link"
                    }
                    onClick={navHideShow}
                    to="/blogs"
                  >
                    Blogs
                  </NavLink>
                </li>
                <li>
                  <Link
                    className="main__contact__btn"
                    to="/contactus"
                    onClick={navHideShow}
                  >
                    Contact Us
                  </Link>
                </li>
                {/* {isUserLoggedIn === "true" || isUserLoggedIn === true ? (
                  <li
                    onClick={() => {
                      navHideShow();
                      navigate("/user/profile");
                    }}
                    className="cursor-pointer"
                  >
                    <span class="material-symbols-outlined font-bold text-[1.8rem] hover:text-green-700">
                      account_circle
                    </span>
                  </li>
                ) : null}
                {isUserLoggedIn === "true" || isUserLoggedIn === true ? (
                  <li
                    onClick={() => {
                      navHideShow();
                      dispatch(signOut());
                    }}
                    className="cursor-pointer"
                  >
                    <span class="material-symbols-outlined font-bold text-[1.7rem] hover:text-green-700">
                      logout
                    </span>
                  </li>
                ) : (
                  <li>
                    <Link
                      className="main__contact__btn"
                      to="#"
                      onClick={() => {
                        navHideShow();
                        handleSignUpInBtn();
                      }}
                    >
                      SignUp | SignIn
                    </Link>
                  </li>
                )} */}
              </ul>
            </div>
            <div className="hum__icon__container">
              <i
                id="humIcons"
                onClick={navHideShow}
                className={` fw-bolder ${
                  humShowHide ? "bi bi-x-lg fs-3" : "bi bi-list fs-1"
                }`}
              ></i>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Navbar;
