import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import "./ClientSliderContainer.css";

import jindal from "../../../../Assets/Images/brands/jindal.png";
import preciseSeamless from "../../../../Assets/Images/brands/precise-seamless.png";
import joshiMemorial from "../../../../Assets/Images/brands/joshi-memorial.png";
import knitCraft from "../../../../Assets/Images/brands/knit-craft.png";
import modiDairy from "../../../../Assets/Images/brands/modi-dairy.png";
import sigma from "../../../../Assets/Images/brands/sigma.png";
import ayushman from "../../../../Assets/Images/brands/ayushman.png";
import bharatGears from "../../../../Assets/Images/brands/bharat-gears.png";
import blissImpax from "../../../../Assets/Images/brands/bliss-impax.png";
import jbr from "../../../../Assets/Images/brands/jbr.png";

export default function ClientSliderContainer() {
  return (
    <div>
      <div className="w-full flex justify-center items-center mt-4">
        <h3 className="bottom__heading w-fit text-2xl">Our Clints</h3>
      </div>
      <div className="">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 5,
            stretch: 0,
            depth: 25,
            modifier: 5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="swiper_container"
        >
          <SwiperSlide>
            <img
              src={jindal}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={preciseSeamless}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={joshiMemorial}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={knitCraft}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={modiDairy}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={sigma}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={ayushman}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={bharatGears}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={blissImpax}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={jbr}
              alt="jindal"
              className="bg-white px-2 py-2 rounded-3 shadow"
            />
          </SwiperSlide>

          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>

            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>

            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}
