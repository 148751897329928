import React from "react";

const InformationSection = () => {
  return (
    <div className="information__container mt-5">
      <div className="container-xxl px-3 px-sm-4">
        <div className="row">
          <div className="col-12 col-sm-4 ">
            <div className="d-flex flex-column align-items-center justify-content-center p-4">
              <i className="bi bi-alarm display-6 fw-bold"></i>
              <h4 className="fw-bold my-2 text-center">Opening Hours</h4>
              <p className="m-0 text-center">Monday to Saturday</p>
              <p className="m-0 text-center">10 AM to 6 PM</p>
            </div>
          </div>
          <div className="col-12 col-sm-4 ">
            <div className="d-flex flex-column align-items-center justify-content-center p-4 border__lines__rl">
              <i className="bi bi-telephone display-6 fw-bold"></i>
              <h4 className="fw-bold my-2 text-center">Call Us Anytime</h4>
              <p
                className="hoverEffect m-0 text-center"
                href="tel:+91 8287411824"
                style={{ cursor: "pointer" }}
              >
                +91 8287411824
              </p>
              <p
                className="hoverEffect m-0 text-center"
                href="tel:+91 7303075122"
                style={{ cursor: "pointer" }}
              >
                +91 7303075122
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="d-flex flex-column align-items-center justify-content-center p-4">
              <i className="bi bi-envelope display-6 fw-bold"></i>
              <h4 className="fw-bold my-2 text-center">Email Us</h4>
              <p
                className="hoverEffect m-0 text-center"
                style={{ cursor: "pointer" }}
                href="mailto:mktg.eei@gmail.com"
              >
                mktg.eei@e2i.co.in
              </p>
              <p
                className="hoverEffect m-0 text-center"
                style={{ cursor: "pointer" }}
                href="mailto:envirozonebo@gmail.com"
              >
                envirozonebo@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationSection;
