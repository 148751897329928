import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";

import "./IndustrySliderContainer.css";

import TextileImg from "../../../../Assets/Images/industryWeSurve/textile.png";
import ChemicalImg from "../../../../Assets/Images/industryWeSurve/chemistry.png";
import AutomationImg from "../../../../Assets/Images/industryWeSurve/engineering.png";
import HospitalImg from "../../../../Assets/Images/industryWeSurve/hospital.png";
import AgricultureImg from "../../../../Assets/Images/industryWeSurve/tractor.png";
import FactoriesImg from "../../../../Assets/Images/industryWeSurve/factory.png";
import CementsImg from "../../../../Assets/Images/industryWeSurve/cement.png";
import BusinessImg from "../../../../Assets/Images/industryWeSurve/business.png";
import HotelImg from "../../../../Assets/Images/industryWeSurve/hotel.png";
import StoneImg from "../../../../Assets/Images/industryWeSurve/stone.png";
import PowerPlantImg from "../../../../Assets/Images/industryWeSurve/powerPlants.png";
import AlmuniumImg from "../../../../Assets/Images/industryWeSurve/aluminum.png";
import CoalImg from "../../../../Assets/Images/industryWeSurve/coal.png";
import PlasticImg from "../../../../Assets/Images/industryWeSurve/plastic.png";
import ConstructionImg from "../../../../Assets/Images/industryWeSurve/construction.png";
import RealStateImg from "../../../../Assets/Images/industryWeSurve/realState.png";
import SugarImg from "../../../../Assets/Images/industryWeSurve/sugar.png";
import TobaccoImg from "../../../../Assets/Images/industryWeSurve/tobacco.png";
import DistelleryImg from "../../../../Assets/Images/industryWeSurve/distillery.png";
import RiceImg from "../../../../Assets/Images/industryWeSurve/rice.png";
import BioImg from "../../../../Assets/Images/industryWeSurve/bio.png";
import EquipmentsImg from "../../../../Assets/Images/industryWeSurve/equipment.png";
import PetroliumImg from "../../../../Assets/Images/industryWeSurve/petrolium.png";
import MetalImg from "../../../../Assets/Images/industryWeSurve/metal.png";
import PaperImg from "../../../../Assets/Images/industryWeSurve/paper.png";
import DrugsImg from "../../../../Assets/Images/industryWeSurve/drugs.png";
import CommercialImg from "../../../../Assets/Images/industryWeSurve/commercial.png";
import DairyImg from "../../../../Assets/Images/industryWeSurve/dairy.png";
import FoodImg from "../../../../Assets/Images/industryWeSurve/food.png";

export default function IndustrySliderContainer() {
  return (
    <div className="industryContainer">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          // rotate: 0,
          // stretch: 0,
          // depth: 200,
          // modifier: 2,
          rotate: 5,
          stretch: 0,
          depth: 25,
          modifier: 5,
        }}
        pagination={{ el: ".industry-swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="industry_swiper_container"
      >
        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={TextileImg} className="w-100" alt="" />
            <h4 className="mt-3">Chemical</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={TextileImg} className="w-100" alt="" />
            <h4 className="mt-3 ">Textile</h4>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={ChemicalImg} className="w-100" alt="" />
            <h4 className="mt-3">Chemical</h4>
          </div>
        </SwiperSlide> */}

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={AutomationImg} className="w-100" alt="" />
            <h4 className="mt-3">Automation</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={HospitalImg} className="w-100" alt="" />
            <h4 className="mt-3">Hospital</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={AgricultureImg} className="w-100" alt="" />
            <h4 className="mt-3">Agriculture</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={FactoriesImg} className="w-100" alt="" />
            <h4 className="mt-3">Factories</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={AgricultureImg} className="w-100" alt="" />
            <h4 className="mt-3">Agriculture</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={CementsImg} className="w-100" alt="" />
            <h4 className="mt-3">Cement</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={BusinessImg} className="w-100" alt="" />
            <h4 className="mt-3">Businesses</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={HotelImg} className="w-100" alt="" />
            <h4 className="mt-3">Hotels</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={StoneImg} className="w-100" alt="" />
            <h4 className="mt-3">Stone Crusher</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={PowerPlantImg} className="w-100" alt="" />
            <h4 className="mt-3">Power Plants</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={AlmuniumImg} className="w-100" alt="" />
            <h4 className="mt-3">Almunium</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={CoalImg} className="w-100" alt="" />
            <h4 className="mt-3">Coal</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={PlasticImg} className="w-100" alt="" />
            <h4 className="mt-3">Plastic</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={ConstructionImg} className="w-100" alt="" />
            <h4 className="mt-3">Constructions</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={RealStateImg} className="w-100" alt="" />
            <h4 className="mt-3">Real States</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={SugarImg} className="w-100" alt="" />
            <h4 className="mt-3">Sugar</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={TobaccoImg} className="w-100" alt="" />
            <h4 className="mt-3">Tobacco</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={DistelleryImg} className="w-100" alt="" />
            <h4 className="mt-3">Distillery</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={RiceImg} className="w-100" alt="" />
            <h4 className="mt-3">Rice Mill</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={BioImg} className="w-100" alt="" />
            <h4 className="mt-3">Biomedicales</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={EquipmentsImg} className="w-100" alt="" />
            <h4 className="mt-3">Equipments</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={PetroliumImg} className="w-100" alt="" />
            <h4 className="mt-3">Petroleum</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={MetalImg} className="w-100" alt="" />
            <h4 className="mt-3">Metal</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={PaperImg} className="w-100" alt="" />
            <h4 className="mt-3">Paper</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={DrugsImg} className="w-100" alt="" />
            <h4 className="mt-3">Drugs & Pharma</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={CommercialImg} className="w-100" alt="" />
            <h4 className="mt-3">Commercial</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={DairyImg} className="w-100" alt="" />
            <h4 className="mt-3">Dairy</h4>
          </div>
        </SwiperSlide>

        <SwiperSlide className="industry_swiper_slide">
          <div className="d-flex flex-column align-items-center justify-content-center rounded-3 shadow p-4 bg-light">
            <img src={FoodImg} className="w-100" alt="" />
            <h4 className="mt-3">Food</h4>
          </div>
        </SwiperSlide>

        <div className="industry-slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>

          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>

          <div className="industry-swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}
