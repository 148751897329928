import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "./Redux/store.js";
import { Provider } from "react-redux";

// bootstrap
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

// bootstrap icon
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// css
import "./Assets/CSS/main.css";
import "./Assets/CSS/topnav.css";
import "./Assets/CSS/root.css";

// window.apiURL = "http://localhost:8080/api/v1";
window.apiURL = "https://backend.envirozone.co.in/api/v1";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
