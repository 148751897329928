import React, { useEffect, useState } from "react";
import productImg from "../../../Assets/Images/home/product-1.png";
import "./CategoryFilterNav.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getAllProducts } from "../../../Redux/Slices/productSlice.js";

const CategoryFilterNav = () => {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const getAllProductsData = async () => {
    const response = await dispatch(getAllProducts());
    if (response?.payload?.success) {
      setProducts(response?.payload?.products);
    }
  };
  useEffect(() => {
    getAllProductsData();
  }, []);
  return (
    <div>
      <div className="container-xxl px-3 px-sm-4">
        <div className="row g-3 g-sm-4">
          <div className="col-12">
            <div className="d-flex">
              <h2 className="display-6 category__page__heading mb-3 mb-sm-4 mt-2">
                All Products
              </h2>
            </div>
          </div>
          {products?.length > 0
            ? products?.map((item, ind) => (
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="category__page__card shadow rounded-3 p-2">
                    <div className="rounded-3 p-3 category__card__imgbg">
                      <img src={productImg} className="w-75" alt="" />
                    </div>
                    <div>
                      <div className="category__card__badge">
                        {item?.features?.map((ele, ind) => (
                          <span className="mt-2">{ele}</span>
                        ))}
                      </div>
                      <h5 className="mt-2">{item.name}</h5>
                      <div className="my-3">
                        <h5 className="m-0">
                          <span>From </span>-<span></span>{" "}
                          <span className="fw-bolder price__txt">
                            {item.price}$
                          </span>
                        </h5>

                        <p className="m-0">Avialable in 7 trims</p>
                      </div>
                      <div>
                        <a className="category__card__btn" href="#">
                          View all trims
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="category__page__card shadow rounded-3 p-2">
              <div className="rounded-3 p-3 category__card__imgbg">
                <img src={productImg} className="w-75" alt="" />
              </div>
              <div>
                <div className="category__card__badge">
                  <span className="mt-2">new</span>
                  <span className="mt-2">Real-time data</span>
                  <span className="mt-2">Touch display</span>
                </div>
                <h5 className="mt-2">Environmental Monitoring System</h5>
                <div className="my-3">
                  <h5 className="m-0">
                    <span>From </span>-<span></span>{" "}
                    <span className="fw-bolder price__txt">12,000$</span>
                  </h5>

                  <p className="m-0">Avialable in 7 trims</p>
                </div>
                <div>
                  <a className="category__card__btn" href="#">
                    View all trims
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilterNav;
