import React, { useState } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./Routes/Navbar";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import Products from "./Pages/Products";
import Industry from "./Pages/Industry";
import Blogs from "./Pages/Blogs";
import ContactUs from "./Pages/ContactUs";
import NoPage from "./Pages/NoPage";
import Footer from "./Routes/Footer";
import ScrollToTop from "./Components/Common/Scrolltotop";
import { useSelector, useDispatch } from "react-redux";
import { open, close } from "./Redux/Slices/openModelSlice.js";
import toast, { Toaster } from "react-hot-toast";
import { signIn, signUp } from "./Redux/Slices/userSlice.js";
import Profile from "./Pages/Profile.js";

const App = () => {
  const signUpInBtnState = useSelector((state) => state.openModel.signUpInBtn);
  const dispatch = useDispatch();
  const [btnType, setBtnType] = useState("signup");
  const [data, setData] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    password: "",
  });
  const setUserData = (value, name) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  async function onSignIn() {
    // Validation
    if (!data.email || !data.password) {
      toast.error("Please Fill All Details!");
      return;
    }
    // Dispatch Action
    const response = await dispatch(signIn(data));
    // On Success Doing Stuff
    if (response?.payload?.success) {
      dispatch(close());
      // Blank All Data
      setData({
        email: "",
        password: "",
        username: "",
        phoneNumber: "",
      });
    }
  }
  async function onSignUp() {
    // Validation
    if (!data.email || !data.password || !data.phoneNumber || !data.username) {
      toast.error("Please Fill All Details!");
      return;
    }
    // Dispatch Action
    const response = await dispatch(signUp(data));
    // On Success Doing Stuff
    if (response?.payload?.success) {
      dispatch(close());
      // Blank All Data
      setData({
        email: "",
        password: "",
        username: "",
        phoneNumber: "",
      });
    }
  }
  return (
    <>
      <HashRouter>
        <ScrollToTop>
          <Toaster />
          {signUpInBtnState ? (
            <div className="fixed top-0 backdrop-brightness-75 h-[100vh] w-[100vw] z-[1005] flex justify-center items-center">
              <div className="bg-white h-96 w-80 rounded-md shadow-lg shadow-white relative">
                {/* Close Button  */}
                <span
                  class="material-symbols-outlined absolute right-4 top-4 font-bold text-4xl cursor-pointer"
                  onClick={() => dispatch(close())}
                >
                  disabled_by_default
                </span>
                <span
                  class="absolute left-8 top-4 font-bold text-4xl cursor-pointer text-green-700"
                  onClick={() => dispatch(close())}
                >
                  E2I
                </span>
                <div className="p-4 mt-16 flex items-center justify-center h-[85%]">
                  <div>
                    <div>
                      {btnType === "signup" && (
                        <input
                          type="text"
                          placeholder="Enter Your Username"
                          className="w-full border-[3px] border-gray-600 p-[6px] rounded-md shadow-md mb-[10px] hover:border-green-600 placeholder:text-green-900"
                          name="username"
                          value={data.username}
                          onChange={(e) =>
                            setUserData(e.target.value, e.target.name)
                          }
                        />
                      )}
                      <input
                        type="email"
                        placeholder="Enter Your Email"
                        className="w-full border-[3px] border-gray-600 p-[6px] rounded-md shadow-md mb-[10px] hover:border-green-600 placeholder:text-green-900"
                        name="email"
                        value={data.email}
                        onChange={(e) =>
                          setUserData(e.target.value, e.target.name)
                        }
                      />
                      {btnType === "signup" && (
                        <input
                          type="number"
                          placeholder="Enter Your Phone No."
                          className="w-full border-[3px] border-gray-600 p-[6px] rounded-md shadow-md mb-[10px] hover:border-green-600 placeholder:text-green-900"
                          name="phoneNumber"
                          value={data.phoneNumber}
                          onChange={(e) =>
                            setUserData(e.target.value, e.target.name)
                          }
                        />
                      )}
                      <input
                        type="password"
                        placeholder="Enter Your Password"
                        className="w-full border-[3px] border-gray-600 p-[6px] rounded-md shadow-md mb-[10px] hover:border-green-600 placeholder:text-green-900"
                        name="password"
                        value={data.password}
                        onChange={(e) =>
                          setUserData(e.target.value, e.target.name)
                        }
                      />
                    </div>
                    <div className="my-2 flex justify-center items-center">
                      {btnType === "signup" ? (
                        <button
                          className="bg-green-500 py-2 px-4 rounded-md shadow-md text-white font-bold hover:bg-green-600"
                          onClick={onSignUp}
                        >
                          Sign Up
                        </button>
                      ) : (
                        <button
                          className="bg-green-500 py-2 px-4 rounded-md shadow-md text-white font-bold hover:bg-green-600"
                          onClick={onSignIn}
                        >
                          Sign In
                        </button>
                      )}
                    </div>
                    <div className="flex justify-center items-center">
                      <span className="text-black">
                        {btnType === "signup" ? (
                          <>
                            <span>Already have an account? </span>
                            <span
                              className="font-bold cursor-pointer text-orange-600"
                              onClick={() => setBtnType("signin")}
                            >
                              Sign In
                            </span>
                          </>
                        ) : (
                          <>
                            <span>Not on E2I yet? </span>
                            <span
                              className="font-bold cursor-pointer text-orange-600"
                              onClick={() => setBtnType("signup")}
                            >
                              Sign Up
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <Navbar />
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/products" element={<Products />} />
            <Route path="/industry" element={<Industry />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/user/profile" element={<Profile />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </HashRouter>
    </>
  );
};

export default App;
