import React, { useState, useEffect } from "react";
import { OrbitingCirclesDemo } from "../../Components/ui/HomeFirstSlide";
import HomeThirdSlide from "../../Components/ui/HomeThirdSlide";
import HomeSecondSlide from "../../Components/ui/HomeSecondSlide";
import HomeFourthSlide from "../../Components/ui/HomeFourthSlide";
import HomeFifthSlide from "../../Components/ui/HomeFifthSlide";

function SliderSection() {
  const [slideNo, setSlideNo] = useState(1);
  const [play, setPlay] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideNo((prevSlideNo) => {
        if (prevSlideNo === 5) {
          return 1;
        } else {
          return prevSlideNo + 1;
        }
      });
    }, 5000);

    if (!play) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [play]);

  const handleLeftArrowClick = () => {
    if (slideNo == 1) {
      setSlideNo(5);
    } else {
      setSlideNo((prevSlideNo) => prevSlideNo - 1);
    }
  };

  const handleRighttArrowClick = () => {
    if (slideNo == 5) {
      setSlideNo(1);
    } else {
      setSlideNo((prevSlideNo) => prevSlideNo + 1);
    }
  };

  const handlePlayPauseClick = () => {
    setPlay(!play);
  };

  return (
    <div className="relative">
      <div
        className="absolute top-[36%] md:top-[42%] cursor-pointer w-fit bg-black z-50 flex justify-center items-center py-2.5 md:py-3 px-2 rounded-r-[10px] md:rounded-r-2xl"
        onClick={handleLeftArrowClick}
      >
        <span className="material-symbols-outlined text-white font-bold">
          arrow_back_ios
        </span>
      </div>
      <div
        className="absolute top-[36%] md:top-[42%] right-0 cursor-pointer w-fit bg-black z-50 flex justify-center items-center py-2.5 md:py-3 px-2 rounded-l-[10px] md:rounded-l-2xl"
        onClick={handleRighttArrowClick}
      >
        <span className="material-symbols-outlined text-white font-bold">
          arrow_forward_ios
        </span>
      </div>
      <div
        className="absolute bottom-4 right-4 cursor-pointer w-fit bg-black z-50 flex justify-center items-center p-3 rounded-full"
        onClick={handlePlayPauseClick}
      >
        {play ? (
          <span className="material-symbols-outlined text-white font-bold">
            pause_circle
          </span>
        ) : (
          <span className="material-symbols-outlined text-white font-bold">
            play_circle
          </span>
        )}
      </div>
      {slideNo === 1 ? (
        <OrbitingCirclesDemo />
      ) : slideNo === 2 ? (
        <HomeSecondSlide />
      ) : slideNo === 3 ? (
        <HomeThirdSlide />
      ) : slideNo === 4 ? (
        <HomeFourthSlide />
      ) : slideNo === 5 ? (
        <HomeFifthSlide />
      ) : (
        <OrbitingCirclesDemo />
      )}
    </div>
  );
}

export default SliderSection;
