import React from "react";
import wateranalyzer from "../../Assets/Images/MagicUI/Product/ins.png";
import water from "../../Assets/Images/MagicUI/Product/water.png";
import aqi from "../../Assets/Images/MagicUI/Product/aqi.png";
import "./Sliders.css";
// import { cn } from "../../lib/utils";
// import AnimatedGridPattern from "../magicui/animated-grid-pattern";
import { ShimmerButtonDemo } from "../ui/ShimmerButton";
import BoxReveal from "../magicui/box-reveal";
// import Ripple from "../magicui/ripple";
// import waterBackground from "../../Assets/Images/MagicUI/Product/watBac.jpg";
// import { NeonGradientCard } from "../magicui/neon-gradient-card";
import FlipText from "../magicui/flip-text";

export default function HomeFourthSlide() {
  return (
    <div className="flex flex-col-reverse md:flex-row">
      <div
        className="bg-cover bg-center flex justify-center flex-col overflow-hidden p-6 md:p-20 w-[100vw] md:w-[50vw] h-auto md:h-[86vh] rounded-lg bg-white "
        // style={{
        //   backgroundImage: `url(${waterBackground})`,
        // }}
      >
        <div className="h-1 w-11 bg-blue-600 mb-2"></div>
        <p className="font-bold text-blue-600 mb-8">
          WE ARE EXPERT IN THIS FIELD
        </p>
        <h3 className="text-4xl font-bold text-black md:text-5xl lg:text-7xl mb-2">
          E2I Dust / SPM Monitor
        </h3>
        <p className="text-justify font-bold text-md mb-6">
          E2I Water Analyzer are{" "}
          <span className="font-semibold text-[#77aa3b]">
            {" "}
            automatic devices
          </span>{" "}
          adapted to their{" "}
          <span className="font-semibold text-[#77aa3b]">
            {" "}
            environment and conceived
          </span>{" "}
          for the continuous or periodical measurement of one or more
          <span className="font-semibold text-[#77aa3b]">
            {" "}
            physical or chemical
          </span>{" "}
          parameters along an{" "}
          <span className="font-semibold text-[#77aa3b]">
            {" "}
            industrial process line
          </span>
          .
        </p>

        {/* <BoxReveal boxColor={"#6ba229"} duration={1.5}> */}
        <div
          className="bg-[#77aa3b] hover:bg-[#497415] text-white font-bold cursor-pointer w-fit px-8 py-2"
          style={{ borderRadius: "8px" }}
        >
          Explore
        </div>

        {/* </BoxReveal> */}
      </div>
      {/* <div className="right-animated-container w-[100vw] md:w-[50vw] h-[40vh] md:h-[82vh]">
        <div className="relative flex w-[100vw] md:w-[50vw] h-[40vh] md:h-[82vh] items-center justify-center overflow-hidden rounded-lg border bg-[#dceef1] p-20 md:shadow-xl">
          <AnimatedGridPattern
            numSquares={40}
            maxOpacity={0.4}
            duration={2}
            repeatDelay={1}
            className={cn(
              "[mask-image:radial-gradient(500px_circle_at_center,white,transparent)]",
              "inset-x-0 inset-y-[-30%] h-[200%] skew-y-12"
            )}
          />
        </div>
        <div className="right-animated-image">
          <img src={wateranalyzer} alt="wateranalyzer" />
        </div>
      </div> */}

      {/* <div className="right-animated-container relative flex w-[100vw] md:w-[50vw] h-[40vh] md:h-[82vh] flex-col items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
        <div className="right-animated-image z-50">
          <img src={wateranalyzer} alt="wateranalyzer" />
        </div>
        <Ripple />
      </div> */}

      {/* <div className="right-animated-container relative flex w-[100vw] md:w-[50vw] h-[40vh] md:h-[82vh] flex-col items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
        <NeonGradientCard className="max-w-sm items-center justify-center text-center">
          <span className="pointer-events-none z-10 h-full whitespace-pre-wrap bg-gradient-to-br from-[#ff2975] from-35% to-[#00FFF1] bg-clip-text text-center text-6xl font-bold leading-none tracking-tighter text-transparent dark:drop-shadow-[0_5px_5px_rgba(0,0,0,0.8)]">
            <div className="right-animated-image z-50">
              <img src={wateranalyzer} alt="wateranalyzer" />
            </div>
          </span>
        </NeonGradientCard>
      </div> */}

      <div
        className="right-animated-container relative flex w-[100vw] md:w-[50vw] h-[30vh] md:h-[86vh] flex-col items-center justify-center overflow-hidden rounded-lg bg-background md:shadow-xl"
        style={{
          background:
            "radial-gradient(circle, #a7ffeb 10%, #1de9b6 50%, #00bfa5 90%)",
        }}
      >
        <div className="right-animated-image z-50">
          <img src={water} alt="wateranalyzer" />
        </div>
        {/* <Ripple /> */}
      </div>
    </div>
  );
}
