import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

const initialState = {
  isLoggedIn: localStorage.getItem("isLoggedIn") || false,
  userId: localStorage.getItem("userId") || null,
  usertype: localStorage.getItem("usertype") || null,
  data: localStorage.getItem("data") || null,
};

// Creating Methods
export const signUp = createAsyncThunk("/user/signUp", async (data) => {
  try {
    const res = await axios.post(`${window.apiURL}/user/signUp`, data);
    const signUpId = toast.loading("Wait! We Are Creating Your Account.");
    if (res.status === 200) {
      toast.dismiss(signUpId);
      toast.success(res?.data?.message, {
        duration: 3000,
        position: "top-center",
      });
      return (await res).data;
    } else {
      toast.dismiss(signUpId);
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.message);
    }
  }
});

export const signIn = createAsyncThunk("/user/signIn", async (data) => {
  try {
    const res = await axios.post(`${window.apiURL}/user/signIn`, data, {
      withCredentials: true, // Important: Include cookies in the request
      headers: {
        "Content-Type": "application/json",
      },
    });
    const signInId = toast.loading("Wait! We Are Login To Your Account.");
    if (res.status === 200) {
      toast.dismiss(signInId);
      toast.success(res?.data?.message, {
        duration: 3000,
        position: "top-center",
      });
      return (await res).data;
    } else {
      toast.dismiss(signInId);
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.message);
    }
  }
});

export const signOut = createAsyncThunk("/user/signOut", async () => {
  try {
    const res = await axios.get(`${window.apiURL}/user/signOut`, {
      withCredentials: true, // Important: Include cookies in the request
      headers: {
        "Content-Type": "application/json",
      },
    });
    const signOutId = toast.loading("Wait! We Are Log Out Of Your Account.");
    if (res.status === 200) {
      toast.dismiss(signOutId);
      toast.success(res?.data?.message, {
        duration: 3000,
        position: "top-center",
      });
      return (await res).data;
    } else {
      toast.dismiss(signOutId);
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.message);
    }
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        if (action?.payload?.success) {
          localStorage.setItem("data", JSON.stringify(action?.payload?.user));
          state.data = action?.payload?.user;
          localStorage.setItem("isLoggedIn", true);
          state.isLoggedIn = true;
          localStorage.setItem("usertype", action?.payload?.user?.usertype);
          state.usertype = action?.payload?.user?.usertype;
          localStorage.setItem("userId", action?.payload?.user?.id);
          state.userId = action?.payload?.user?.id;
        }
      })
      .addCase(signOut.fulfilled, (state, action) => {
        if (action?.payload?.success) {
          localStorage.setItem("data", null);
          state.data = null;
          localStorage.setItem("isLoggedIn", false);
          state.isLoggedIn = false;
          localStorage.setItem("usertype", null);
          state.usertype = null;
          localStorage.setItem("userId", null);
          state.userId = null;
        }
      });
  },
});

export const {} = userSlice.actions;
export default userSlice.reducer;
