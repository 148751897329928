import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";

import "./ProductSliderContainer.css";

import product1 from "../../../../Assets/Images/home/product-1.png";

export default function ProductSliderContainer() {
  return (
    <div className="productContainer">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          // rotate: 10,
          // stretch: 10,
          // depth: 10,
          // modifier: 3,
          rotate: 5,
          stretch: 0,
          depth: 25,
          modifier: 5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="product_swiper_container"
      >
        <SwiperSlide className="product_swiper_slide h-auto mx-4">
          <div className="bg-light shadow rounded-3 p-2 product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="product_swiper_slide h-auto  mx-4">
          <div className="bg-light shadow rounded-3 p-2 h-auto product_container">
            <div className="rounded-3 p-3 d-flex align-itmes-center justify-content-center service__card__imgbg">
              <img src={product1} className="w-25" alt="" />
            </div>
            <h3 className="text-center mt-3">
              Environmental Monitoring System
            </h3>
            <p className="text-center">
              It describes the processes and activities that need to take place
              to characterize and monitor the quality of environment.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a className="services__btn__read" href="#">
                READ MORE
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a
                className="services__btn__view main__contact__btn my-3"
                href="#"
              >
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </SwiperSlide>

        <div className="product-slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>

          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>

          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}
