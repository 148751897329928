import React, { useState } from "react";
// images
import heroBg from "../../Assets/Images/main-bg.jpg";

//components
import MainHeading from "../../Components/Common/MainHeading";

const HeroContainer = () => {
  const [number, setNumber] = useState("");

  const handleSubmitPhoneNumberBtn = async () => {
    if (number?.length < 10) {
      return alert("Please Enter a valid Phone Number.");
    }
    try {
      console.log(number);
      setNumber("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="hero__container mt-16"
        style={{ backgroundImage: `url(${heroBg})` }}
      >
        <div className="container-xxl px-3 px-sm-4">
          <div>
            <div
              className="hero__content__container d-flex align-items-center flex-column mx-auto p-3 p-sm-4"
              style={{ width: "100%", maxWidth: "900px" }}
            >
              <h1 className="text-center main__txt display-5 ">
                Serving Planet For Better Living
              </h1>
              <p className="text-center">
                Dedicated to fostering a harmonious relationship between
                humanity and the environment through conscientious initiatives
                and forward-thinking strategies.
              </p>
              <div className="row g-2">
                <div className="col-6 col-sm-3">
                  <div className="bg__green h-100 px-3 py-2 rounded-3 d-flex align-items-center justify-content-center flex-column">
                    <p className="text-center m-0 fs-4 fw-bold">700+</p>
                    <p className="text-center m-0">Industries</p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="bg__green h-100 px-3 py-2 rounded-3 d-flex align-items-center justify-content-center flex-column">
                    <p className="text-center m-0 fs-4 fw-bold">850+</p>
                    <p className="text-center m-0">
                      Monitoring
                      <br /> Stations
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="bg__green h-100 px-3 py-2 rounded-3 d-flex align-items-center justify-content-center flex-column">
                    <p className="text-center m-0 fs-4 fw-bold">690+</p>
                    <p className="text-center m-0">
                      Happy
                      <br /> Clients
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="bg__green h-100 px-3 py-2 rounded-3 d-flex align-items-center justify-content-center flex-column">
                    <p className="text-center m-0 fs-4 fw-bold">800+</p>
                    <p className="text-center m-0">Projects</p>
                  </div>
                </div>
              </div>
              <div
                className="input-group my-4"
                style={{ width: "100%", maxWidth: "500px" }}
              >
                <input
                  type="number"
                  className="form-control"
                  placeholder="Submit your mobile no. Our team will Contact you"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <button
                  className="btn btn-outline-secondary fw-bolder"
                  type="button"
                  id="button-addon2"
                  onClick={handleSubmitPhoneNumberBtn}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroContainer;
