import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signUpInBtn: false,
};

export const openModelSlice = createSlice({
  name: "openModel",
  initialState,
  reducers: {
    open: (state) => {
      state.signUpInBtn = true;
    },
    close: (state) => {
      state.signUpInBtn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { open, close } = openModelSlice.actions;

export default openModelSlice.reducer;
